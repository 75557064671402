
import { Component, Vue, Watch } from '@/lib/decorator';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import FilterPreview from '@/components/FilterPreview.vue';

import PartnersModule from '@/store/partners';
import AppTable from '@/components/ui/table/Table.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppTableColumnContext from '@/components/ui/table/TableColumnContext.vue';
import AppScrollbar from '@/components/ui/scroll/Scrollbar.vue';
import AppTableStatus from '@/components/ui/table/__components/TableStatus.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { tabsNav as employeesTabs } from '@/store/employees/entityTabs';
import userModule from '@/store/user';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppRow,
    AppCol,
    AppTableStatus,
    AppScrollbar,
    AppTableColumnContext,
    StatusActive,
    TextDatetime,
    TextPhone,
    AppTable,
    AppHeaderMain,
    FilterPreview,
  },
})
export default class PartnersMain extends Vue {
  headers: TableHeaderInterface[] = [];
  hiddenHeaders: TableHeaderInterface[] = [];

  toAdd(): void {
    this.$router.push({ name: 'partner_add' });
  }

  get canEditPartner(): boolean {
    return userModule.userHasPermission('CAN_EDIT_PARTNER');
  }

  get settings(): PageInterface {
    return PartnersModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return PartnersModule.filterModule.filterSettings;
  }

  get anyActiveTab(): string {
    const employeesPath = Object.values(employeesTabs)
      .filter((tab: { pathName: string }) => {
        return userModule.canUserRead(tab.pathName);
      })
      .shift();

    return employeesPath ? employeesPath.pathName : '';
  }

  @Watch('settings.table.titles')
  getTitles(titles: TableHeaderInterface[]): void {
    this.headers = [...titles];
  }

  selectAmount(value: string): void {
    PartnersModule.updatePageAmountItems(value);
  }

  async resetFilters(): Promise<void> {
    await PartnersModule.setPageToBegin();
    await PartnersModule.clearSort();
    await PartnersModule.filterModule.resetFilter();
    await PartnersModule.getList();
  }

  async updateList(): Promise<void> {
    await PartnersModule.setPageToBegin();
    await PartnersModule.filterModule.updateFilter();
    await PartnersModule.getList();
  }

  sort(header: { id: string; sort: { value: string } }): void {
    PartnersModule.sort({ field: header.id, sort: header.sort.value });
  }

  mounted(): void {
    PartnersModule.init();
  }
}
